interface HierarchyItem {
  readonly text: string
  readonly value: number | null
  readonly level: number
}

enum DeleteStatus {
  Allowed,
  HasOrganizationalUnits,
  HasProfessionalUnits,
  HasTeamMembers,
}

export { HierarchyItem, DeleteStatus }
