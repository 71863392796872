var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [!_vm.onlySymbol ? _c('span', {
    staticClass: "mt-10"
  }, [_vm._v("Lädt ...")]) : _vm._e(), !_vm.onlyText ? _c('div', {
    staticClass: "loader"
  }) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }