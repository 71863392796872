
import { computed, defineComponent, PropType, reactive, ref, watch } from '@vue/composition-api'
import { mdiPlus, mdiPencil, mdiDelete, mdiAlertOutline } from '@mdi/js'

import CommonLoadingSpinner from '@/components/common/CommonLoadingSpinner.vue'

import { useNotify } from '@/store'

import { dateDashNotationToDot } from '@/utils/convertDate'
import { handleError } from '@/utils/handleError'
import { hasSufficientRights } from '@/utils/hasSufficientRights'

import { useDeleteProfUnitManagementTeamMember } from '@/api/profUnitManagementTeamMember'
import { useDeleteOrgUnitManagementTeamMember } from '@/api/orgUnitManagementTeamMember'

import { DeleteStatus } from '@/views/baseData/views/types'
import { OrgUnitTreeStructure } from '@/api/types/orgUnit'
import { CorporateUnitTreeStructure } from '@/api/types/corporateUnit'
import { ProfUnitTreeStructure } from '@/api/types/profUnit'
import { Rights } from '@/api/types/right'
import { isOfType } from '@/utils/types/isOfType'
import { UNIT_TYPE } from '@/api/types/misc'
import { OrgUnitManagementTeamMember } from '@/api/types/orgUnitManagementTeamMember'
import { ProfUnitManagementTeamMember } from '@/api/types/profUnitManagementTeamMember'
import { PspProfUnitAssignment } from '@/api/types/pspProfUnitAssignment'

export default defineComponent({
  name: 'TreeViewWithPreview',
  components: {
    CommonLoadingSpinner,
  },
  props: {
    treeViewItems: {
      type: Array as PropType<CorporateUnitTreeStructure[] | ProfUnitTreeStructure[]>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    treeViewChildrenProperty: {
      type: String,
      required: true,
    },
    previewItem: {
      type: Object,
      default: null,
    },
  },
  setup: (props, { root, emit }) => {
    const { addNotification } = useNotify()

    const sortedTreeViewItems = computed(() => sortDeep(props.treeViewItems))

    const IGNORED_PROPERTIES = [
      'id',
      'organizationalUnits',
      'links',
      'unitType',
      'children',
      'profUnits',
      'title',
      'type',
      'capacityRelevantProfiles',
      checkTeamMemberReadRights(),
    ]

    function checkTeamMemberReadRights(): string | null {
      if (isOfType<ProfUnitTreeStructure>(props.treeViewItems, 'professionalUnitManagementTeamMembers')) {
        return hasSufficientRights(Rights.PROFESSIONAL_UNIT_MANAGEMENT_TEAM_MEMBER_READ)
          ? null
          : 'professionalUnitManagementTeamMembers'
      } else {
        return hasSufficientRights(Rights.ORGANIZATIONAL_UNIT_MANAGEMENT_TEAM_MEMBER_READ)
          ? null
          : 'organizationalUnitManagementTeamMembers'
      }
    }

    const DATE_PROPERTIES = ['createdAt', 'updatedAt']

    function itemIsSelected(item: OrgUnitTreeStructure | CorporateUnitTreeStructure) {
      return item?.id === props.previewItem?.id && item?.unitType === props.previewItem?.unitType
    }

    const deleteStatus = computed(() => {
      if (props.previewItem?.organizationalUnits?.length > 0) return DeleteStatus.HasOrganizationalUnits
      if (props.previewItem?.profUnits?.length > 0) return DeleteStatus.HasProfessionalUnits
      if (
        props.previewItem?.organizationalUnitManagementTeamMembers?.length > 0 ||
        props.previewItem?.professionalUnitManagementTeamMembers?.length > 0
      )
        return DeleteStatus.HasTeamMembers
      return DeleteStatus.Allowed
    })

    const { deleteProfUnitManagementTeamMember } = useDeleteProfUnitManagementTeamMember()
    const { deleteOrgUnitManagementTeamMember } = useDeleteOrgUnitManagementTeamMember()

    async function onDeleteTeamMember(
      member: OrgUnitManagementTeamMember | ProfUnitManagementTeamMember
    ): Promise<void> {
      try {
        if (isOfType<OrgUnitManagementTeamMember>(member, 'organizationalUnit')) {
          await deleteOrgUnitManagementTeamMember(member.id)
        } else if (isOfType<ProfUnitManagementTeamMember>(member, 'professionalUnit')) {
          await deleteProfUnitManagementTeamMember(member.id)
        }

        emit('refetch')

        addNotification({
          text: root.$t('misc.deleted') as string,
          type: 'success',
          timeout: 3000,
        })
      } catch (error: unknown) {
        handleError(error)
      }
    }

    function flatTreeViewItems(treeViewArray: CorporateUnitTreeStructure[] | ProfUnitTreeStructure[]) {
      let allOrganizationalUnits: ProfUnitTreeStructure[] = []

      const newTreeViewItems = treeViewArray.map((item) => {
        if (item.organizationalUnits?.length) {
          allOrganizationalUnits = [...allOrganizationalUnits, ...item.organizationalUnits]
        }

        return item
      })

      return [
        ...newTreeViewItems,
        ...(allOrganizationalUnits.length ? flatTreeViewItems(allOrganizationalUnits) : allOrganizationalUnits),
      ]
    }

    const parentAndChildTreeViewItems = ref<CorporateUnitTreeStructure[] | ProfUnitTreeStructure[]>([])

    watch(props, () => {
      parentAndChildTreeViewItems.value =
        root.$route.name === 'base-data-corporate-structure' ? flatTreeViewItems(sortedTreeViewItems.value) : []
    })

    function isTeamMemberArray(property) {
      if (
        property === 'professionalUnitManagementTeamMembers' ||
        property === 'organizationalUnitManagementTeamMembers'
      ) {
        return true
      }
      return false
    }

    function isPspAssignmentsArray(pspAssignments) {
      if (isOfType<PspProfUnitAssignment>(pspAssignments[0], 'pspAccount')) {
        return true
      }
      return false
    }

    function sortAlphabetical(unitArray) {
      if (isOfType<ProfUnitTreeStructure>(unitArray[0], 'profUnits')) {
        return unitArray.sort((a, b) => {
          const longNameA = a.longName.toUpperCase()
          const longNameB = b.longName.toUpperCase()
          return longNameA < longNameB ? -1 : longNameA > longNameB ? 1 : 0
        })
      } else if (isOfType<CorporateUnitTreeStructure>(unitArray[0], 'organizationalUnits')) {
        return unitArray.sort((a, b) => {
          const titleA = a.title.toUpperCase()
          const titleB = b.title.toUpperCase()
          return titleA < titleB ? -1 : titleA > titleB ? 1 : 0
        })
      }
    }

    function sortDeep(
      treeViewArray: CorporateUnitTreeStructure[] | ProfUnitTreeStructure[]
    ): CorporateUnitTreeStructure[] | ProfUnitTreeStructure[] {
      sortAlphabetical(treeViewArray)
      if (isOfType<ProfUnitTreeStructure>(treeViewArray[0], 'profUnits')) {
        return treeViewArray.map((item) => {
          sortAlphabetical(item.profUnits)

          if (item.profUnits?.length) {
            sortDeep(item.profUnits)
          }

          return item
        })
      } else if (isOfType<CorporateUnitTreeStructure>(treeViewArray[0], 'organizationalUnits')) {
        return treeViewArray.map((item) => {
          sortAlphabetical(item.organizationalUnits)

          if (item.organizationalUnits?.length) {
            sortDeep(item.organizationalUnits)
          }

          return item
        })
      }
      return treeViewArray
    }

    return reactive({
      icons: {
        mdiPlus,
        mdiPencil,
        mdiDelete,
        mdiAlertOutline,
      },
      enums: {
        DeleteStatus,
      },
      constants: {
        Rights,
        UNIT_TYPE,

        IGNORED_PROPERTIES,
        DATE_PROPERTIES,
      },
      state: {
        deleteStatus,

        parentAndChildTreeViewItems,
        sortedTreeViewItems,
      },
      listeners: {
        onDeleteTeamMember,
      },
      functions: {
        dateDashNotationToDot,
        itemIsSelected,

        isPspAssignmentsArray,
        isTeamMemberArray,

        hasSufficientRights,
        sortDeep,
      },
    })
  },
})
