var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "px-16"
  }, [_c('div', {
    staticClass: "min-w-1/2 pa-4 rounded-lg v-treeview--sticky white"
  }, [_vm._t("tree-view-header"), _vm.isLoading ? _c('CommonLoadingSpinner', {
    attrs: {
      "only-text": ""
    }
  }) : _c('v-treeview', {
    staticClass: "mt-5 card--fixed",
    attrs: {
      "open": _vm.state.parentAndChildTreeViewItems.length ? _vm.state.parentAndChildTreeViewItems : _vm.state.sortedTreeViewItems,
      "items": _vm.state.sortedTreeViewItems,
      "item-children": _vm.treeViewChildrenProperty,
      "open-all": "",
      "return-object": ""
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "px-3 cursor-pointer w-100",
          class: {
            'primary--text': _vm.functions.itemIsSelected(item)
          },
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('click:label', item);
            }
          }
        }, [_vm._v(" " + _vm._s(item.title) + " ")])];
      }
    }, {
      key: "append",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._t("tree-plus-icon", function () {
          return [_c('v-tooltip', {
            attrs: {
              "top": "",
              "open-delay": "200"
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function fn(_ref3) {
                var on = _ref3.on,
                    attrs = _ref3.attrs;
                return [_c('v-btn', _vm._g(_vm._b({
                  attrs: {
                    "icon": ""
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.$emit('click:plusIconLabel', item);
                    }
                  }
                }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")])], 1)];
              }
            }], null, true)
          }, [_vm._v(" " + _vm._s(_vm.$t("baseData.".concat(_vm.$route.meta.i18nKey && _vm.$route.meta.i18nKey, ".actions.addChild"))) + " ")])];
        })];
      }
    }], null, true)
  }), _vm._t("default")], 2), _c('v-divider', {
    attrs: {
      "vertical": ""
    }
  }), _c('div', {
    staticClass: "text-center min-w-1/2"
  }, [_vm.previewItem ? _c('div', {
    staticClass: "v-treeview-preview__card--sticky rounded-lg"
  }, [_c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('span', {
    staticClass: "text-h5"
  }, [_vm._v(" " + _vm._s(_vm.previewItem.title) + " ")]), _c('v-divider', {
    staticClass: "my-5"
  }), _vm._l(Object.entries(_vm.previewItem), function (entry, index) {
    return _c('v-row', {
      key: index,
      staticClass: "text-left"
    }, [!_vm.constants.IGNORED_PROPERTIES.includes(entry[0]) ? [_c('v-col', {
      staticClass: "mb-2 mr-4 text-right",
      attrs: {
        "tag": "strong",
        "cols": "4"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("baseData.".concat(_vm.$route.meta.i18nKey && _vm.$route.meta.i18nKey, ".detailSide.").concat(entry[0]))) + " ")]), _vm.constants.DATE_PROPERTIES.includes(entry[0]) ? _c('v-col', [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(entry[1])) + " ")]) : entry[1] && entry[1].name ? _c('v-col', [_vm._v(" " + _vm._s(entry[1].name) + " ")]) : Array.isArray(entry[1]) && _vm.functions.isPspAssignmentsArray(entry[1]) ? _c('v-col', {
      staticClass: "px-0"
    }, [_c('ul', {
      staticClass: "px-0"
    }, [_c('li', {
      staticClass: "d-flex justify-space-between mb-5"
    }, [_c('span', {
      staticClass: "font-weight-bold"
    }, [_vm._v(" " + _vm._s(_vm.$t("baseData.profUnits.detailSide.pspAccount")) + " ")]), _c('span', {
      staticClass: "font-weight-bold"
    }, [_vm._v(" " + _vm._s(_vm.$t("baseData.profUnits.detailSide.ratio")) + " ")])]), _vm._l(entry[1], function (pspAssignment) {
      return _c('li', {
        key: pspAssignment.id,
        staticClass: "d-flex justify-space-between my-5"
      }, [_c('span', [_vm._v(" " + _vm._s(pspAssignment.pspAccount.name) + " ")]), _c('span', [_vm._v(" " + _vm._s(pspAssignment.ratio) + " ")])]);
    })], 2)]) : Array.isArray(entry[1]) && _vm.functions.isTeamMemberArray(entry[0]) ? _c('v-col', {
      staticClass: "d-flex align-start pt-2"
    }, [_vm._t("table-teamMember", function () {
      return [entry[1].length ? _c('v-simple-table', {
        staticClass: "management-team-table",
        attrs: {
          "dense": "",
          "fixed-header": ""
        }
      }, [_c('thead', [_c('tr', [_c('td', {
        staticClass: "font-weight-bold"
      }, [_vm._v(" " + _vm._s(_vm.$t('baseData.misc.managementTeam.table.col.title.name')) + " ")]), _c('td', {
        staticClass: "font-weight-bold"
      }, [_vm._v(" " + _vm._s(_vm.$t('baseData.misc.managementTeam.table.col.title.role')) + " ")]), _c('td', {
        staticClass: "font-weight-bold"
      }, [_vm._v(" " + _vm._s(_vm.$t('baseData.misc.managementTeam.table.col.title.actions')) + " ")])])]), _c('tbody', _vm._l(entry[1], function (item) {
        return _c('tr', {
          key: item.id
        }, [_c('td', [_vm._v(" " + _vm._s(item.user.name) + " ")]), _c('td', [_vm._v(" " + _vm._s(item.role) + " ")]), _c('td', [_vm._t("edit-teamMember", function () {
          return [_vm.functions.hasSufficientRights(_vm.constants.Rights.BASIC_READ) ? _c('v-btn', {
            attrs: {
              "small": "",
              "icon": "",
              "elevation": "0"
            },
            on: {
              "click": function click($event) {
                return _vm.$emit('click:editIconMember', item);
              }
            }
          }, [_c('v-icon', {
            attrs: {
              "small": ""
            }
          }, [_vm._v(" " + _vm._s(_vm.icons.mdiPencil) + " ")])], 1) : _vm._e()];
        }), _vm._t("delete-teamMember", function () {
          return [_c('v-menu', {
            attrs: {
              "offset-y": ""
            },
            scopedSlots: _vm._u([{
              key: "activator",
              fn: function fn(_ref4) {
                var on = _ref4.on,
                    attrs = _ref4.attrs;
                return [_c('v-btn', _vm._g(_vm._b({
                  attrs: {
                    "small": "",
                    "icon": "",
                    "elevation": "0"
                  }
                }, 'v-btn', attrs, false), on), [_c('v-icon', {
                  attrs: {
                    "small": ""
                  }
                }, [_vm._v(" " + _vm._s(_vm.icons.mdiDelete) + " ")])], 1)];
              }
            }], null, true)
          }, [_c('v-card', [_c('v-card-title', {
            staticClass: "text-subtitle-1"
          }, [_vm._v(" " + _vm._s(_vm.$t('baseData.misc.managementTeam.delete.text')) + " ")]), _c('v-card-actions', [_c('v-btn', {
            staticClass: "rounded-lg",
            attrs: {
              "small": "",
              "outlined": "",
              "elevation": "0"
            },
            on: {
              "click": function click($event) {
                return _vm.listeners.onDeleteTeamMember(item);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm.$t('form.yes')) + " ")]), _c('v-spacer'), _c('v-btn', {
            staticClass: "rounded-lg",
            attrs: {
              "small": "",
              "outlined": "",
              "elevation": "0"
            }
          }, [_vm._v(" " + _vm._s(_vm.$t('form.no')) + " ")])], 1)], 1)], 1)];
        })], 2)]);
      }), 0)]) : _vm._e(), _vm._t("add-teamMember", function () {
        return [_vm.functions.hasSufficientRights(_vm.constants.Rights.BASIC_READ) ? _c('v-tooltip', {
          attrs: {
            "top": "",
            "open-delay": "200"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref5) {
              var on = _ref5.on,
                  attrs = _ref5.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                class: ["".concat(entry[1].length && 'ml-6')],
                attrs: {
                  "x-small": "",
                  "fab": "",
                  "elevation": "0"
                },
                on: {
                  "click": function click($event) {
                    return _vm.$emit('click:plusIconMember');
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")])], 1)];
            }
          }], null, true)
        }, [_vm._v(" " + _vm._s(_vm.$t('baseData.misc.managementTeam.add.title')) + " ")]) : _vm._e()];
      })];
    })], 2) : typeof entry[1] === 'boolean' && !entry[1] ? _c('v-col', [_vm._v(_vm._s(_vm.$t("baseData.profUnits.detailSide.notRelevant")))]) : typeof entry[1] === 'boolean' && entry[1] ? _c('v-col', [_vm._v(_vm._s(_vm.$t("baseData.profUnits.detailSide.relevant")))]) : !entry[1] || !entry[1].length ? _c('v-col', [_vm._v(" - ")]) : _c('v-col', [_vm._v(" " + _vm._s(entry[1]) + " ")])] : _vm._e()], 2);
  }), _vm._t("additional-cols"), _c('div', {
    staticClass: "justify-end d-flex mt-7"
  }, [_vm._t("delete-unit", function () {
    return [_vm.state.deleteStatus !== _vm.enums.DeleteStatus.Allowed ? _c('v-tooltip', {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref6) {
          var on = _ref6.on,
              attrs = _ref6.attrs;
          return [_c('v-icon', _vm._g(_vm._b({
            attrs: {
              "color": "rgba(0, 0, 0, 0.26)"
            }
          }, 'v-icon', attrs, false), on), [_vm._v(" " + _vm._s(_vm.icons.mdiAlertOutline) + " ")])];
        }
      }], null, false, 3291160704)
    }, [_vm.state.deleteStatus === _vm.enums.DeleteStatus.HasTeamMembers ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('baseData.corporateStructure.detailSide.cannotDeleteItemWithLeaders')) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('baseData.corporateStructure.detailSide.cannotDeleteItemWithElements')))])]) : _vm._e(), _c('v-btn', {
      staticClass: "mx-2 rounded-lg",
      attrs: {
        "color": "red",
        "disabled": _vm.state.deleteStatus !== _vm.enums.DeleteStatus.Allowed,
        "small": ""
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('click:delete');
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('form.delete')) + " ")])];
  }), _vm._t("edit-unit", function () {
    return [_c('v-btn', {
      staticClass: "mx-2 rounded-lg",
      attrs: {
        "outlined": "",
        "small": ""
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('click:edit');
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('form.edit')) + " ")])];
  })], 2)], 2)], 1)], 1) : _c('p', [_vm._v(" " + _vm._s(_vm.$t("baseData.".concat(_vm.$route.meta.i18nKey && _vm.$route.meta.i18nKey, ".detailSide.noItemSelected"))) + " ")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }