
import { defineComponent, reactive, ref } from '@vue/composition-api'
import cloneDeep from 'lodash-es/cloneDeep'
import { mdiPlus } from '@mdi/js'

import TreeViewWithPreview from '@/views/baseData/components/TreeViewWithPreview.vue'

import { useNotify } from '@/store'

import { useDeleteProfUnit, useGetProfUnits } from '@/api/profUnit'
import {
  useCreateProfUnitManagementTeamMember,
  useUpdateProfUnitManagementTeamMember,
} from '@/api/profUnitManagementTeamMember'

import { handleError } from '@/utils/handleError'
import { hasSufficientRights } from '@/utils/hasSufficientRights'

import { ProfUnitOutput, ProfUnitId, ProfUnitTreeStructure } from '@/api/types/profUnit'
import {
  NewProfUnitManagementTeamMember,
  ProfUnitManagementTeamMember,
} from '@/api/types/profUnitManagementTeamMember'
import { UNIT_TYPE } from '@/api/types/misc'
import { Rights } from '@/api/types/right'

export default defineComponent({
  name: 'ProfUnits',
  components: {
    TreeViewWithPreview,
    AddEditProfUnitDialog: () => import('@/views/baseData/views/profUnits/components/AddEditProfUnitDialog.vue'),
    AddEditManagementTeamMemberDialog: () =>
      import('@/views/baseData/components/AddEditManagementTeamMemberDialog.vue'),
    CommonDeleteDialog: () => import('@/components/common/CommonDeleteDialog.vue'),
  },
  setup: (_, { root }) => {
    const { addNotification } = useNotify()

    const previewItem = ref<ProfUnitOutput | null>(null)

    const itemToEdit = ref<ProfUnitOutput | null>(null)

    const isAddEditProfUnitModalOpen = ref(false)

    const profUnitParentId = ref<ProfUnitId | null>(null)

    function onClickAddChildProfUnit(parentProfUnit?: ProfUnitTreeStructure): void {
      if (parentProfUnit) {
        profUnitParentId.value = parentProfUnit.id
      }

      isAddEditProfUnitModalOpen.value = true
    }

    function onAddedOrEditedProfUnit(profUnit: ProfUnitOutput): void {
      previewItem.value = profUnit
      profUnitParentId.value = null
      init()
    }

    function onCloseAddEditProfUnitDialog(): void {
      isAddEditProfUnitModalOpen.value = false
      profUnitParentId.value = null
      itemToEdit.value = null
    }

    const isAddEditProfUnitManagementTeamMemberDialogOpen = ref(false)

    const { createProfUnitManagementTeamMember } = useCreateProfUnitManagementTeamMember()

    async function onAddProfUnitManagementTeamMember(teamMember: NewProfUnitManagementTeamMember): Promise<void> {
      try {
        await createProfUnitManagementTeamMember(teamMember)

        init()

        onCloseProfUnitManagementTeamMemberDialog()

        addNotification({
          text: root.$t('misc.added') as string,
          type: 'success',
          timeout: 3000,
        })
      } catch (error: unknown) {
        handleError(error)
      }
    }

    const isMemberEditMode = ref(false)

    const memberToEdit = ref<ProfUnitManagementTeamMember | null>(null)

    const { updateProfUnitManagementTeamMember } = useUpdateProfUnitManagementTeamMember()

    function onClickEditIconMember(teamMember: ProfUnitManagementTeamMember): void {
      isAddEditProfUnitManagementTeamMemberDialogOpen.value = true

      isMemberEditMode.value = true

      memberToEdit.value = { ...teamMember }
    }

    async function onEditProfUnitManagementTeamMember(): Promise<void> {
      try {
        if (!memberToEdit.value) return

        Object.entries(memberToEdit.value as ProfUnitManagementTeamMember).forEach(
          ([key, value]) => value && value.id && memberToEdit.value && (memberToEdit.value[key] = value.id)
        )

        await updateProfUnitManagementTeamMember(memberToEdit.value.id, memberToEdit.value)

        init()

        onCloseProfUnitManagementTeamMemberDialog()

        addNotification({
          text: root.$t('misc.edited') as string,
          type: 'success',
          timeout: 3000,
        })
      } catch (error: unknown) {
        handleError(error)
      }
    }

    function onCloseProfUnitManagementTeamMemberDialog(): void {
      isAddEditProfUnitManagementTeamMemberDialogOpen.value = false

      isMemberEditMode.value = false

      memberToEdit.value = null
    }

    const isDeleteDialogOpen = ref(false)

    const { deleteProfUnit } = useDeleteProfUnit()

    async function onDelete(): Promise<void> {
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await deleteProfUnit(previewItem.value!.id)

        addNotification({
          text: root.$t('misc.deleted') as string,
          type: 'success',
          timeout: 3000,
        })

        init()
      } catch (error: unknown) {
        handleError(error)
      }

      isDeleteDialogOpen.value = false
    }

    function onClickEdit(): void {
      isAddEditProfUnitModalOpen.value = true

      itemToEdit.value = previewItem.value
    }

    const {
      exec: getProfUnits,
      data: profUnits,
      isLoading: isLoadingProfUnits,
    } = useGetProfUnits<ProfUnitTreeStructure>()

    function populateProfUnitsProperty(): void {
      profUnits.value.forEach((profUnit) => {
        const children = profUnits.value.filter((unit) => unit.parent && unit.parent.id === profUnit.id)

        profUnit.profUnits = children
      })
    }

    async function init(): Promise<void> {
      await getProfUnits()

      populateProfUnitsProperty()

      previewItem.value = profUnits.value.find((profUnit) => profUnit.id === previewItem.value?.id) || null

      profUnits.value = profUnits.value.filter((profUnit) => !profUnit.parent)
    }

    init()

    return reactive({
      icons: {
        mdiPlus,
      },
      constants: {
        Rights,

        UNIT_TYPE,
      },
      state: {
        profUnits,
        isLoadingProfUnits,

        isAddEditProfUnitModalOpen,
        profUnitParentId,

        previewItem,

        isAddEditProfUnitManagementTeamMemberDialogOpen,

        isMemberEditMode,
        memberToEdit,

        isDeleteDialogOpen,

        itemToEdit,
      },
      listeners: {
        onClickAddChildProfUnit,
        onAddedOrEditedProfUnit,

        onCloseAddEditProfUnitDialog,

        onAddProfUnitManagementTeamMember,

        onClickEditIconMember,
        onEditProfUnitManagementTeamMember,
        onCloseProfUnitManagementTeamMemberDialog,

        onDelete,

        onClickEdit,
      },
      functions: {
        cloneDeep,

        init,

        hasSufficientRights,
      },
    })
  },
})
