import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'

import {
  UseGetOrgUnitManagementTeamMembers,
  UseCreateOrgUnitManagementTeamMember,
  UseUpdateOrgUnitManagementTeamMember,
  UseDeleteOrgUnitManagementTeamMember,
  OrgUnitManagementTeamMember,
  NewOrgUnitManagementTeamMember,
  OrgUnitManagementTeamMemberId,
} from '@/api/types/orgUnitManagementTeamMember'
import { PromiseType } from '@/utils/types/PromiseType'

const VERSION = 'v1'
const RESOURCE = 'organizational-unit-management-team-members'

function useGetOrgUnitManagementTeamMembers(): UseGetOrgUnitManagementTeamMembers {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetOrgUnitManagementTeamMembers['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
    params: { size: 9999 },
  })
  return axios
}

function useCreateOrgUnitManagementTeamMember(): UseCreateOrgUnitManagementTeamMember {
  const axios = useAxios<
    PromiseType<ReturnType<UseCreateOrgUnitManagementTeamMember['createOrgUnitManagementTeamMember']>>
  >({ method: 'POST', url: `/${VERSION}/${RESOURCE}` })

  function createOrgUnitManagementTeamMember(
    data: NewOrgUnitManagementTeamMember
  ): Promise<OrgUnitManagementTeamMember> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createOrgUnitManagementTeamMember,
  }
}

function useUpdateOrgUnitManagementTeamMember(): UseUpdateOrgUnitManagementTeamMember {
  const axios = useAxios<
    PromiseType<ReturnType<UseUpdateOrgUnitManagementTeamMember['updateOrgUnitManagementTeamMember']>>
  >({ method: 'PUT' })

  function updateOrgUnitManagementTeamMember(
    id: OrgUnitManagementTeamMemberId,
    data: OrgUnitManagementTeamMember
  ): Promise<OrgUnitManagementTeamMember> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updateOrgUnitManagementTeamMember,
  }
}

function useDeleteOrgUnitManagementTeamMember(): UseDeleteOrgUnitManagementTeamMember {
  const axios = useAxios<
    PromiseType<ReturnType<UseDeleteOrgUnitManagementTeamMember['deleteOrgUnitManagementTeamMember']>>
  >({ method: 'DELETE' })

  function deleteOrgUnitManagementTeamMember(id: OrgUnitManagementTeamMemberId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteOrgUnitManagementTeamMember,
  }
}

export {
  useGetOrgUnitManagementTeamMembers,
  useCreateOrgUnitManagementTeamMember,
  useUpdateOrgUnitManagementTeamMember,
  useDeleteOrgUnitManagementTeamMember,
}
