import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'

import {
  UseGetProfUnitManagementTeamMembers,
  UseCreateProfUnitManagementTeamMember,
  UseUpdateProfUnitManagementTeamMember,
  UseDeleteProfUnitManagementTeamMember,
  ProfUnitManagementTeamMember,
  NewProfUnitManagementTeamMember,
  ProfUnitManagementTeamMemberId,
} from '@/api/types/profUnitManagementTeamMember'
import { PromiseType } from '@/utils/types/PromiseType'

const VERSION = 'v1'
const RESOURCE = 'professional-unit-management-team-members'

function useGetProfUnitManagementTeamMembers(): UseGetProfUnitManagementTeamMembers {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetProfUnitManagementTeamMembers['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
    params: { size: 9999 },
  })
  return axios
}

function useCreateProfUnitManagementTeamMember(): UseCreateProfUnitManagementTeamMember {
  const axios = useAxios<
    PromiseType<ReturnType<UseCreateProfUnitManagementTeamMember['createProfUnitManagementTeamMember']>>
  >({ method: 'POST', url: `/${VERSION}/${RESOURCE}` })

  function createProfUnitManagementTeamMember(
    data: NewProfUnitManagementTeamMember
  ): Promise<ProfUnitManagementTeamMember> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createProfUnitManagementTeamMember,
  }
}

function useUpdateProfUnitManagementTeamMember(): UseUpdateProfUnitManagementTeamMember {
  const axios = useAxios<
    PromiseType<ReturnType<UseUpdateProfUnitManagementTeamMember['updateProfUnitManagementTeamMember']>>
  >({ method: 'PUT' })

  function updateProfUnitManagementTeamMember(
    id: ProfUnitManagementTeamMemberId,
    data: ProfUnitManagementTeamMember
  ): Promise<ProfUnitManagementTeamMember> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updateProfUnitManagementTeamMember,
  }
}

function useDeleteProfUnitManagementTeamMember(): UseDeleteProfUnitManagementTeamMember {
  const axios = useAxios<
    PromiseType<ReturnType<UseDeleteProfUnitManagementTeamMember['deleteProfUnitManagementTeamMember']>>
  >({ method: 'DELETE' })

  function deleteProfUnitManagementTeamMember(id: ProfUnitManagementTeamMemberId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteProfUnitManagementTeamMember,
  }
}

export {
  useGetProfUnitManagementTeamMembers,
  useCreateProfUnitManagementTeamMember,
  useUpdateProfUnitManagementTeamMember,
  useDeleteProfUnitManagementTeamMember,
}
