import { UserEmail } from '@/api/types/user'

interface MetaProperties {
  readonly id: number
  readonly createdBy: UserEmail
  readonly updatedBy: UserEmail
  readonly createdAt: string // YYYY-MM-DD
  readonly updatedAt: string // YYYY-MM-DD
  readonly title: string
}

interface BasicEntity {
  readonly id: number
  readonly name: string
}

enum UNIT_TYPE {
  'CORP_UNIT' = 'CORP_UNIT',
  'ORG_UNIT' = 'ORG_UNIT',
  'PROF_UNIT' = 'PROF_UNIT',
}

export { MetaProperties, BasicEntity, UNIT_TYPE }
