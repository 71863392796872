var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "mt-12",
    attrs: {
      "fluid": ""
    }
  }, [_c('TreeViewWithPreview', {
    key: _vm.state.profUnits.length,
    staticClass: "justify-center d-flex",
    attrs: {
      "tree-view-items": _vm.state.profUnits,
      "is-loading": _vm.state.isLoadingProfUnits,
      "tree-view-children-property": "profUnits",
      "preview-item": _vm.state.previewItem
    },
    on: {
      "click:label": function clickLabel($event) {
        _vm.state.previewItem = $event;
      },
      "click:plusIconLabel": function clickPlusIconLabel($event) {
        return _vm.listeners.onClickAddChildProfUnit($event);
      },
      "click:plusIconMember": function clickPlusIconMember($event) {
        _vm.state.isAddEditProfUnitManagementTeamMemberDialogOpen = true;
      },
      "click:editIconMember": function clickEditIconMember($event) {
        return _vm.listeners.onClickEditIconMember($event);
      },
      "click:delete": function clickDelete($event) {
        _vm.state.isDeleteDialogOpen = true;
      },
      "click:edit": function clickEdit($event) {
        return _vm.listeners.onClickEdit();
      },
      "refetch": function refetch($event) {
        return _vm.functions.init();
      }
    },
    scopedSlots: _vm._u([{
      key: "tree-view-header",
      fn: function fn() {
        return [_vm.functions.hasSufficientRights(_vm.constants.Rights.PROFESSIONAL_UNIT_CREATE) && _vm.functions.hasSufficientRights(_vm.constants.Rights.BASIC_READ) ? _c('v-btn', {
          staticClass: "rounded-lg",
          attrs: {
            "small": "",
            "elevation": "0",
            "color": "primary",
            "outlined": ""
          },
          on: {
            "click": function click($event) {
              return _vm.listeners.onClickAddChildProfUnit();
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.icons.mdiPlus) + " ")]), _vm._v(" " + _vm._s(_vm.$t('baseData.profUnits.actions.addProfUnit')) + " ")], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "tree-plus-icon",
      fn: function fn() {
        return [!_vm.functions.hasSufficientRights(_vm.constants.Rights.PROFESSIONAL_UNIT_CREATE) || !_vm.functions.hasSufficientRights(_vm.constants.Rights.BASIC_READ) ? _c('div') : _vm._e()];
      },
      proxy: true
    }, {
      key: "table-teamMember",
      fn: function fn() {
        return [!_vm.functions.hasSufficientRights(_vm.constants.Rights.PROFESSIONAL_UNIT_MANAGEMENT_TEAM_MEMBER_READ) ? _c('div') : _vm._e()];
      },
      proxy: true
    }, {
      key: "edit-teamMember",
      fn: function fn() {
        return [!_vm.functions.hasSufficientRights(_vm.constants.Rights.PROFESSIONAL_UNIT_MANAGEMENT_TEAM_MEMBER_UPDATE) || !_vm.functions.hasSufficientRights(_vm.constants.Rights.BASIC_READ) ? _c('div') : _vm._e()];
      },
      proxy: true
    }, {
      key: "delete-teamMember",
      fn: function fn() {
        return [!_vm.functions.hasSufficientRights(_vm.constants.Rights.PROFESSIONAL_UNIT_MANAGEMENT_TEAM_MEMBER_DELETE) ? _c('div') : _vm._e()];
      },
      proxy: true
    }, {
      key: "add-teamMember",
      fn: function fn() {
        return [!_vm.functions.hasSufficientRights(_vm.constants.Rights.PROFESSIONAL_UNIT_MANAGEMENT_TEAM_MEMBER_CREATE) || !_vm.functions.hasSufficientRights(_vm.constants.Rights.BASIC_READ) ? _c('div') : _vm._e()];
      },
      proxy: true
    }, {
      key: "delete-unit",
      fn: function fn() {
        return [!_vm.functions.hasSufficientRights(_vm.constants.Rights.PROFESSIONAL_UNIT_DELETE) ? _c('div') : _vm._e()];
      },
      proxy: true
    }, {
      key: "edit-unit",
      fn: function fn() {
        return [!_vm.functions.hasSufficientRights(_vm.constants.Rights.PROFESSIONAL_UNIT_UPDATE) || !_vm.functions.hasSufficientRights(_vm.constants.Rights.BASIC_READ) ? _c('div') : _vm._e()];
      },
      proxy: true
    }])
  }, [_vm.state.isAddEditProfUnitModalOpen ? _c('AddEditProfUnitDialog', {
    attrs: {
      "prof-unit-to-edit": _vm.functions.cloneDeep(_vm.state.itemToEdit),
      "prof-unit-parent-id": _vm.state.profUnitParentId,
      "tree-view-items": _vm.state.profUnits
    },
    on: {
      "added-edited": function addedEdited($event) {
        return _vm.listeners.onAddedOrEditedProfUnit($event);
      },
      "close": function close($event) {
        return _vm.listeners.onCloseAddEditProfUnitDialog();
      }
    },
    model: {
      value: _vm.state.isAddEditProfUnitModalOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isAddEditProfUnitModalOpen", $$v);
      },
      expression: "state.isAddEditProfUnitModalOpen"
    }
  }) : _vm._e(), _vm.state.isAddEditProfUnitManagementTeamMemberDialogOpen ? _c('AddEditManagementTeamMemberDialog', {
    attrs: {
      "is-edit-mode": _vm.state.isMemberEditMode,
      "member-to-edit": _vm.state.memberToEdit,
      "entity-id": _vm.state.previewItem.id,
      "entity-type": _vm.constants.UNIT_TYPE.PROF_UNIT
    },
    on: {
      "add": function add($event) {
        return _vm.listeners.onAddProfUnitManagementTeamMember($event);
      },
      "edit": function edit($event) {
        return _vm.listeners.onEditProfUnitManagementTeamMember($event);
      },
      "close": function close($event) {
        return _vm.listeners.onCloseProfUnitManagementTeamMemberDialog();
      }
    },
    model: {
      value: _vm.state.isAddEditProfUnitManagementTeamMemberDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isAddEditProfUnitManagementTeamMemberDialogOpen", $$v);
      },
      expression: "state.isAddEditProfUnitManagementTeamMemberDialogOpen"
    }
  }) : _vm._e(), _vm.state.isDeleteDialogOpen ? _c('CommonDeleteDialog', {
    on: {
      "cancel": function cancel($event) {
        _vm.state.isDeleteDialogOpen = false;
      },
      "delete": function _delete($event) {
        return _vm.listeners.onDelete();
      }
    },
    model: {
      value: _vm.state.isDeleteDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isDeleteDialogOpen", $$v);
      },
      expression: "state.isDeleteDialogOpen"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('baseData.profUnits.dialog.delete.text', {
    name: "".concat(_vm.state.previewItem.longName)
  })) + " ")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }